import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { data } from '../services/mock-data';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedEventService } from '../services/shared-event.service';
import { MenuItemPriceService } from '../services/menu-item-price.service';
import * as _ from 'lodash';
import { RestaurantService } from '../services/restaurant.service';
import { ConceptPluSalesService } from '../services/concept-plu-sales.service';
import { Subject, debounceTime, distinctUntilChanged, forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modeling-tool-pricing-table',
  templateUrl: './modeling-tool-pricing-table.component.html',
  styleUrls: ['./modeling-tool-pricing-table.component.scss']
})
export class ModelingToolPricingTableComponent implements OnInit, OnChanges {
  @Input()
  selectedCategory: string;
  @Input()
  groupName: string;
  @Input() activeId;
  @Input()
  restaurantNum: any;
  @Input()
  auvsFor: any;
  @Input()
  competitors: any;
  @Input()
  currentRes: any;
  @Input()
  dateRange: any;
  @Input()
  checkedCompetitors: boolean;
  @Input()
  availableSoldTypes: any;
  @Input()
  availableServiceMode: any;
  @Input()
  isServiceLoaded: any;
  @Output() onValueChange = new EventEmitter<object>();
  @Output() cardDetails = new EventEmitter<object>();
  @Output() showCard = new EventEmitter<boolean>();
  assetsUrl: string;
  menuItems: any[] = [];
  copyMenuItems: any;
  btnFlag: boolean = false;
  btnText: string = 'Load RBI Recommendation';
  projectionFlag: boolean = false;
  projectionValue: string = 'Enter Projected Change %';
  priceToBeSaved: any[] = [];
  isLoadRBIClicked: boolean = false;
  selectedServiceMode: string = 'In-store';
  selectedServiceModeId: string = '11e6cd44-4b53-4579-bd23-8f719677470a';
  priceIncrease;
  priceChangePercentage;
  tempPriceIncrease = '';
  tempPriceChangePercentage = '';
  saveModel: boolean = false;
  savedDate = new Date();
  canChange = true;
  isSaved = false;
  tableData = [];
  conceptResId;
  selectedSoldTypeId = [];
  isLoading = true;
  response = [];
  totalCurrentAuv = 0;
  totalProjectedAuv = 0;
  totalcurrentSales = 0;
  totalProjectedSales = 0;
  totalcurrentgp = 0;
  totalProjectedGp = 0;
  isTestEnv = false;
  timePeriodId;
  conceptId;
  modelVersion = "v1";
  normalizeValue = 30;
  pluLength=30;
  salesData;
  cardData = [];
  private parameterChangeSubject = new Subject<any>();

  constructor(private ngbmodalService: NgbModal, private sharedService: SharedEventService, private menuService: MenuItemPriceService, private restaurantService: RestaurantService, private conceptSalesService: ConceptPluSalesService,private toastr: ToastrService) {
    this.sharedService.canChange.subscribe(res => {
      this.canChange = res;
    });
    this.sharedService.isSaved.subscribe(res => {
      this.isSaved = res;
    });
    this.sharedService.savedModelVersion.subscribe(res => {
      this.modelVersion = res;
    });

    this.parameterChangeSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(() => this.refreshTableData());

  }

  ngOnInit(): void {
    this.assetsUrl = environment.assetUrl;
    if (environment.isTestEnvironment) {
      this.isTestEnv = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showCard.emit(false);
    this.isLoading = true;
    this.cardDetails.emit([])
    this.projectionFlag = false;
    this.modelVersion = "v1";
    this.sharedService.isSaved.emit(false);
    if (changes['currentRes']) {
      this.restaurantService.getCurrentRestaurantByNum(this.currentRes.num).subscribe((res: any) => {
        this.conceptId = res[0].conceptId;
        this.conceptResId = res[0].id;
        this.parameterChangeSubject.next(changes);
      })
    } else if (!changes['currentRes']) {
      this.parameterChangeSubject.next(changes);
    }
    this.isLoadRBIClicked = false;
    this.priceToBeSaved = [];
    this.saveModel = false;
  }
  async refreshTableData() {
    switch (this.auvsFor) {
      case '30 Days':
        this.normalizeValue = 30;
        break;
      case '7 Days':
        this.normalizeValue = 7;
        break;
      case '90 Days':
        this.normalizeValue = 90;
        break;
    }
    this.isLoading = true;
    this.selectedSoldTypeId = [];
    this.availableSoldTypes?.forEach(element => {
      if (element.isSelected) {
        this.selectedSoldTypeId.push(element.id);
      }
    });
    this.pluLength = this.dateRange.filter(el => el.name == this.auvsFor)[0].periodLength;
    this.timePeriodId = this.dateRange.filter(el => el.name == this.auvsFor)[0].id;
    
    if (this.selectedSoldTypeId.length != 1) {
      this.selectedSoldTypeId = null;
    }

    let restaurantIds = [this.currentRes.id];
    if (this.checkedCompetitors) this.competitors.forEach(el => restaurantIds.push(el.id));
    this.selectedCategory = this.selectedCategory?.replace(/&/g, '%26');

    let modellingSources = [this.menuService.getCompetitorPluPriceForCompetitorGroup(restaurantIds.join(','), this.selectedCategory, this.selectedServiceModeId, this.selectedSoldTypeId ? this.selectedSoldTypeId[0] : null)];
    modellingSources.push(this.conceptSalesService.GetConceptRestaurantPluSalesForCategory(this.conceptResId, this.timePeriodId, this.selectedCategory, this.selectedServiceModeId, this.selectedSoldTypeId ? this.selectedSoldTypeId[0] : null));
    forkJoin(modellingSources).subscribe(res => {
      this.response = res;
      this.tableData = [];
      let groupedData = {};
      this.competitors.forEach((el) => {
        groupedData[el.id] = [];
      })
      this.salesData = this.response[1];
      let tempGroupedData = _.groupBy(res[0], "competitorRestaurantId");
      
      _.each(tempGroupedData, (val, key) => {
        groupedData[key] = val;
      });
      _.each(groupedData,((val,key)=>{
        val = val.sort((a, b) => {
          return a.groupSortId - b.groupSortId || a.subgroupSortId - b.subgroupSortId || a.sortId - b.sortId;
        })
      }))     
      groupedData[this.currentRes.id]?.forEach(el => {
        if(el.groupName!=='Upcharges'){
        let obj = {
          conceptPluId: el.conceptPluId,
          pluName: el.name,
          pluNum: el.num,
          pluPrice: el.price,
          sortId: el.sortId,
          timeperiodId: this.timePeriodId,
          conceptId: this.conceptId,
          servicemodeId: this.selectedServiceModeId,
          subgroupName: el.subgroupName
        }
        this.tableData.push(obj);
      }
      });
      if (this.checkedCompetitors) {
        _.each(groupedData, (val, key) => {
          if (key != this.currentRes.id && key != '00000000-0000-0000-0000-000000000000') {
            this.tableData.forEach(el => {
              let itemIdx = val.findIndex(td => td.conceptPluId == el.conceptPluId);
              if (itemIdx != -1) {
                el[key] = val[itemIdx].price;
              } else {
                let compId = this.competitors.filter(el1 => key == el1.id)[0].competitorId;
                let unpricedIdx = tempGroupedData['00000000-0000-0000-0000-000000000000'].findIndex(td => td.conceptPluId == el.conceptPluId && td.competitorId == compId);
                if (unpricedIdx != -1) {
                  el[key] = 0;
                } else {
                  el[key] = -1;
                }
              }
            })
          }
        });
      }
      this.tableData.forEach(el => {

        let itemidx = this.response[1].findIndex(td => td.conceptPluId == el.conceptPluId);
        if (itemidx != -1) {
          el.regUnits = this.response[1][itemidx].totalQty;
          el.pluCostAmt = this.response[1][itemidx].regCostAmt;
          el.totalCostAmt = this.response[1][itemidx].totalCostAmt; 
        }
      })


      this.restaurantService.restaurantplupricemodels(this.restaurantNum, this.selectedCategory, this.timePeriodId).subscribe({
        next:resp=>{
          this.isLoading = false;
          if(resp.length>=1){
            this.tableData.forEach(el => {
              let itemidx = resp.findIndex(td => td.conceptPluId == el.conceptPluId);
              if (itemidx != -1) {
                el.overridePrice = (resp[itemidx].overridePrice)?.toFixed(2);
                el.projectedUnits = resp[itemidx].projectedUnits;
                el.projectedUnitsCalculation = resp[itemidx].projectedUnits != null ? (((resp[itemidx].projectedUnits / this.normalizeValue) * 100) / 100).toFixed(2) : null;
              }
            })
            this.modelingToolCalculations();
          }
        },
        error:e=>{
        }
      })

      this.modelingToolCalculations();
      this.copyMenuItems = JSON.parse(JSON.stringify(this.tableData));
    })
  }

  modelingToolCalculations() {
    this.totalCurrentAuv = 0;
    this.totalProjectedAuv = 0;
    this.totalcurrentSales = 0;
    this.totalProjectedSales = 0;
    this.totalcurrentgp = 0;
    this.totalProjectedGp = 0;
    this.tableData.forEach(el => {
      el.cogsCurrent = el.pluCostAmt * el.regUnits;
      el.actualDailyUnits = +((el.regUnits / this.normalizeValue));
      el.actualDailyCost = +((el.pluCostAmt/this.normalizeValue));
      el.regAmt = el.actualDailyUnits * el.pluPrice;
      el.actualDailySales = +((el.regAmt / this.normalizeValue));
      el.costofOneUnit = el.totalCostAmt/el.regUnits;
      el.projectedAmt = (el.projectedUnitsCalculation !=null || el.projectedUnitsCalculation !=undefined) 
                        ? el.regAmt*(el.projectedUnitsCalculation/el.actualDailyUnits) : el.regAmt;
      el.gpAmt = el.regAmt-(el.actualDailyUnits * el.costofOneUnit);
      el.gpProjectedAmt = (el.projectedUnitsCalculation !=null || el.projectedUnitsCalculation !=undefined) 
                          ? (el.gpAmt * +((el.projectedUnitsCalculation/el.actualDailyUnits))) : el.gpAmt;
      el.dailyUnits = (el.projectedUnits !=null && el.projectedUnits>=0 ? el.projectedUnits / this.normalizeValue : el.regUnits / this.normalizeValue);
      el.dailySales = el.regAmt*(el.projectedAmt ? el.projectedAmt / this.normalizeValue : el.regAmt / this.normalizeValue);
    })
    let totalDailyUnits = 0;
    let totalDailySales = 0;
    let totalDailyGp = 0;
    let totalActualUnits = 0;
    let totalActualSales = 0;
    let totalActualGp =0;
    this.tableData.forEach(el => {
      totalDailyUnits += el.dailyUnits;
      totalDailySales += el.projectedAmt;
      totalActualUnits += el.actualDailyUnits;
      totalActualSales += el.regAmt;
      totalActualGp += el.gpAmt;
      totalDailyGp += el.gpProjectedAmt;
    });
    this.cardData = [];
    let monthly = {
      title: 'Monthly Estimates',
      overallUnits: totalDailyUnits * 30,
      overallSales: totalDailySales * 30,
      overallGP: totalDailyGp * 30,
      actualUnits: totalActualUnits * 30,
      actualSales: totalActualSales * 30,
      actualGp: totalActualGp*30,
    }
    monthly['unitsDiff']= monthly.overallUnits - monthly.actualUnits;
    monthly['salesDiff']= monthly.overallSales - monthly.actualSales;
    monthly['gpDiff'] = monthly.overallGP - monthly.actualGp;
    this.cardData.push(monthly);
    let annual = {
      title: 'Annual Estimates',
      overallUnits: totalDailyUnits * 365,
      overallSales: totalDailySales * 365,
      overallGP: totalDailyGp * 365,
      actualUnits: totalActualUnits * 365,
      actualSales: totalActualSales * 365,
      actualGp: totalActualGp*365,
    }
    annual['unitsDiff']= annual.overallUnits - annual.actualUnits;
    annual['salesDiff']= annual.overallSales - annual.actualSales;
    annual['gpDiff'] = annual.overallGP - annual.actualGp;
    this.cardData.push(annual);
    if (this.tableData && this.tableData.length > 0) {
      this.showCard.emit(true);
    }
    this.cardDetails.emit(this.cardData);

  }

  ChangeServiceMode(value) {
    this.selectedServiceModeId = value;
    this.showCard.emit(false);
    this.refreshTableData();

  }
  loadButtons(isloaded, categoryId) {
    // if(this.btnFlag){

    //   this.btnFlag =false;
    // }

    // else{

    //   this.btnFlag=true;

    // }
    this.menuItems.forEach(el => {
      el.RbiPriceLoaded = true;
    })
    this.isLoadRBIClicked = true;

  }
  getIcon(brand: string) {
    if (brand == undefined || brand == null) {
      return this.assetsUrl + "/assets/BK Logo@2x.png";
    }
    if (brand == 'McDonalds') {
      return this.assetsUrl + "/assets/MCD Logo@2x.png";
    }
    if (brand == 'Jack In A Box') {
      return this.assetsUrl + "/assets/JackInTheBox Logo@2x.png";
    }
    if (brand == 'Wendys') {
      return this.assetsUrl + "/assets/Wendys Logo@2x.png";
    }
    if (brand == 'Sonic') {
      return this.assetsUrl + "/assets/sonic-logo.png"
    }
    if (brand == 'Whataburger') {
      return this.assetsUrl + "/assets/Whataburger_logo.png"
    }
    if (brand == 'Taco Bell') {
      return this.assetsUrl + "/assets/taco-bell.png"
    }
    if (brand == 'In N Out') {
      return this.assetsUrl + "/assets/In-N-Out-Burger.webp"
    }
    if (brand == 'Hardees') {
      return this.assetsUrl + "/assets/hardees.webp"
    }
    if (brand == "BK") {
      return this.assetsUrl + "/assets/BK Logo@2x.png"
    }
    if (brand == 'Carl\'s Jr.') {
      return this.assetsUrl + "/assets/carlsjr_logo.png"
    }
    return "";
  }
  // rsiProjectionButton(){
  //   if(this.projectionFlag){
  //     this.projectionValue = 'Load RSI Projections*'
  //     this.projectionFlag =false;
  //   }

  //   else{
  //     this.projectionValue = 'Hide RSI Projections*';
  //     this.projectionFlag=true;

  //   }
  openManageModel(content) {
    this.tempPriceIncrease = '';
    this.tempPriceChangePercentage = '';
    this.ngbmodalService.open(content, { windowClass: 'manageModel' });
  }
  EnterProjectionChange() {
    let canCalculate = 0;
    this.tableData.forEach(el=>{
      if(el.regUnits == undefined || el.regUnits ==null || el.totalCostAmt== undefined || el.totalCostAmt == null){
        canCalculate +=1;
      }
    });
    if(canCalculate  ==0){
      this.projectionFlag = true;
      this.saveModel = true;
      this.priceIncrease = this.tempPriceIncrease == 'true' ? true : false;
      this.priceChangePercentage = +this.tempPriceChangePercentage;
      this.tableData.forEach(el => {
        if (this.priceIncrease == true) {
          el.projectedUnits =(el.regUnits + (el.regUnits * this.priceChangePercentage / 100));
          el.projectedUnitsCalculation = ((el.regUnits + (el.regUnits * this.priceChangePercentage / 100))/this.pluLength).toFixed(2);
        } else {
          el.projectedUnits = (el.regUnits - (el.regUnits * this.priceChangePercentage / 100));
          el.projectedUnitsCalculation = ((el.regUnits - (el.regUnits * this.priceChangePercentage / 100))/this.pluLength).toFixed(2);
        }
      })
      this.modelingToolCalculations();
    }else{
      this.toastr.error('Cannot compute the monthly and annual estimations because of missing item cost.');
    }
    this.ngbmodalService.dismissAll();
    if (this.priceToBeSaved.length > 0 || this.saveModel) {
      this.onValueChange.emit(this.tableData);
      this.sharedService.canChange.emit(false);
    } else {
      this.sharedService.canChange.emit(true);
    }
  }

  restrictDigit(index){
    this.tableData[index].projectedUnitsCalculation = (this.tableData[index].projectedUnitsCalculation)?.toFixed(2);
  }

  onChangeprice(index, isProjetedRSI?, value?) {
    let canCalculate = 0;
    this.tableData.forEach(el=>{
      if(el.regUnits == undefined || el.regUnits ==null || el.totalCostAmt== undefined || el.totalCostAmt == null){
        canCalculate +=1;
      }
    });
    if(isProjetedRSI){
      this.tableData[index].projectedUnitsCalculation =  (value && value.length>0)? +value : null;
    }
    if(canCalculate ==0){
      if (isProjetedRSI) {
        let projectedAuvChangedCount = 0;
        this.tableData[index].projectedUnits = (value && value.length>0)? +value * this.pluLength: null;
        this.tableData.forEach((el, idx) => {
          let changed = el.projectedUnits != this.copyMenuItems[idx].projectedUnits;
          if (changed) {
            projectedAuvChangedCount++;
          }
        })
        if (projectedAuvChangedCount > 0) {
          this.projectionFlag = true;
        }
      } else {
        let newPrice = parseFloat(value).toFixed(2);
        this.tableData[index].overridePrice = newPrice;
      }
      if (this.priceToBeSaved.length == 0) {
        if (this.copyMenuItems[index]?.overridePrice != this.tableData[index]?.overridePrice || this.copyMenuItems[index]?.projectedUnits != this.tableData[index]?.projectedUnits) {
          this.priceToBeSaved.push(this.tableData[index])
        }
      } else {
        let idx = this.priceToBeSaved.findIndex(el => el?.conceptPluId == this.tableData[index].conceptPluId);
        this.priceToBeSaved.splice(idx, 1);
        if (this.copyMenuItems[index]?.overridePrice != this.tableData[index]?.overridePrice || this.copyMenuItems[index]?.projectedUnits != this.tableData[index]?.projectedUnits) {
          this.priceToBeSaved.push(this.tableData[index]);
        }
      }
      this.modelingToolCalculations();
      if (this.priceToBeSaved.length > 0 || this.saveModel) {
        this.onValueChange.emit(this.tableData);
        this.sharedService.canChange.emit(false);
      } else {
        this.sharedService.canChange.emit(true);
      }
    }
    else{
      if (!isProjetedRSI){
        let newPrice = parseFloat(value).toFixed(2);
        this.tableData[index].overridePrice = newPrice;
      }
      this.toastr.error('Cannot compute the monthly and annual estimations because of missing item cost.');
    }
  }
  removeComma(num:string){
    return num = num.replace(/\,/g,'');
  }
}
