import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  constructor(private http: HttpClient) { }
  private apiRoot: string = `${environment.restaurantUrl}/api`

  getCurrentRestaurantByNum(num: any) {
    let url = `${this.apiRoot}/restaurants?Filters=num==${num}`;
    return this.http.get(url);
  }

  getRestaurantsForUser() {
    let url = `${this.apiRoot}/restaurantrus/GetRestaurantRusForUserId`;
    return this.http.get(url);
  }

  GetRestaurantNationalBenchMark(resNum: number) {
    let url = `${this.apiRoot}/restaurants/GetRestaurantNationalBenchMark?num=${resNum}`;
    return this.http.get(url);
  }

  GetRestaurantBkcDivisionBenchMark(resNum: number) {
    let url = `${this.apiRoot}/restaurants/GetRestaurantBkcDivisionBenchMark?num=${resNum}`;
    return this.http.get(url);
  }

  // GetRestaurantClusterBenchMark(resNum: number) {
  //   let url = `${this.apiRoot}/restaurants/GetRestaurantClusterBenchMark?num=${resNum}`;
  //   return this.http.get(url);
  // }

  // GetRestaurantDmaBenchMark(resNum: number) {
  //   let url = `${this.apiRoot}/restaurants/GetRestaurantDmaBenchMark?num=${resNum}`;
  //   return this.http.get(url);
  // }
  GetRestaurantSalesBandBenchMark(resNum: number) {
    let url = `${this.apiRoot}/restaurants/GetRestaurantSalesBandBenchMark?num=${resNum}`;
    return this.http.get(url);
  }

  GetUserSettings(searchTerm: string) {
    let url = `${this.apiRoot}/usersettings${searchTerm}`;
    return this.http.get(url);
  }
  GetUserSettingForMemberRecordId(recordId:string) {
    
      let url = `${this.apiRoot}/usersettings/GetUserSettingForMemberRecordId?recordId=${recordId}`;
      return this.http.get(url);
    
  }
  CreatePricingAlerts(userId: string, alerts: any[]) {
    const settingsDoc = {
      userId: userId,
      settingsDocument: {
        pricing_alerts: alerts
      }
    };
    //console.log(settingsDoc);
    let result = this.http.post<any>(`${this.apiRoot}/usersettings/`, settingsDoc);
    return result;
  }
  CreateAcceptedTermsUserSetting(userId: string) {
    const settingsDoc = {
      userId: userId,
      settingsDocument: {
        pricing_alerts: [],
        lastLogin: dayjs().format('MM/DD/YYYY hh:mm:ss')
      }
    };
    //console.log(settingsDoc);
    let result = this.http.post<any>(`${this.apiRoot}/usersettings/`, settingsDoc);
    return result;
  }
  UpdatePricingAlerts(userId: string, settingsDocument: any[]) {
    //console.log(settingsDocument);
    let result = this.http.put<any>(`${this.apiRoot}/usersettings/${userId}`, settingsDocument);
    return result;
  }
  //UpdateUserSettingUiConfigById?userSettingId=3fa85f64-5717-4562-b3fc-2c963f66afa6'

  UpdateUserSettingUiConfigById(userId: string, configDoc: any) {
    let result = this.http.put<any>(`${this.apiRoot}/usersettings/UpdateUserSettingUiConfigById?userSettingId=${userId}`, configDoc);
    return result;
  }

  sendSMSOptinMessage(phone:string) {
    let result = this.http.post<any>(`${this.apiRoot}/sms/SendMobileConfirmationText?phoneNumber=${phone}`,{});
    return result;
  }

  restaurantplupricemodels(storeId: number, subGroupName: string, timeperiodId: string) {
    if (subGroupName == "All") {
      let result = this.http.get<any>(`${this.apiRoot}/restaurantplupricemodels/GetLatestRestaurantPluPriceModelsForStore?storeId=${storeId}&timeperiodId=${timeperiodId}`);
      return result;
    } else {
      let result = this.http.get<any>(`${this.apiRoot}/restaurantplupricemodels/GetLatestRestaurantPluPriceModelsForStore?storeId=${storeId}&subGroupName=${subGroupName}&timeperiodId=${timeperiodId}`);
      return result;
    }
  }

}
