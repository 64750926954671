import { preserveWhitespacesDefault } from '@angular/compiler';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { RestaurantService } from 'src/app/services/restaurant.service';
import { SharedEventService } from 'src/app/services/shared-event.service';
import { SaveModelModellingtoolComponent } from '../save-model-modellingtool/save-model-modellingtool.component';

@Component({
  selector: 'app-restaurant-selector',
  templateUrl: './restaurant-selector.component.html',
  styleUrls: ['./restaurant-selector.component.scss']
})
export class RestaurantSelectorComponent implements OnInit {
  @Output() OnRestaurantNum = new EventEmitter<number>();
  @Output() OnRestaurantGroup = new EventEmitter<string>();
  @Input() emitOnFirstLoad = true;
  @Input() isMultiSelect = false;
  @Input() preSelectedRestaurantGroup: any;
  @Input() preSelectedRestaurantNumber: any;
  @Input() previousSelectedRestaurantGroup: any;
  @Input() modalPriceToSave: any;
  @Input() selectedModelingRes: any;
  @Input() changeRestaurant: any;
  @ViewChild('allSelected') allSelected: MatOption;
  @ViewChild('notSavedModel') mymodal: ElementRef;
  @ViewChild(SaveModelModellingtoolComponent) saveModalRef: SaveModelModellingtoolComponent;

  restaurantSelectForm: FormGroup;

  constructor(private reataurantService: RestaurantService, private fb: FormBuilder,private sharedService: SharedEventService, private modalService: NgbModal) { 
    this.sharedService.canChange.subscribe(res=>{
      this.canChange = res;  
    })
  }

  searchText: string = '';
  groupSearchText: string = '';
  availableModelingRestaurant: any[] = [];
  availableModelingRestaurantGroup: any[] = [];
  selectedRestaurantNumber: any;
  selectedRestaurantGroup: any;
  selectedRestaurantNumbers: any[] = [];
  numberSelected = 0;
  canChange=true;
  changedRes = true;
  groupName;
  value;
  previousSelectedRestaurantNumber:any;
  ngOnInit(): void {
      if (this.changeRestaurant?.length > 0) {
        var options = this.changeRestaurant.filter((item, i, arr) => arr.findIndex((t) => t.groupId === item.groupId) === i);
        this.availableModelingRestaurantGroup = options;
        this.availableModelingRestaurantGroup = this.availableModelingRestaurantGroup.sort((a, b) => {
          let textA = a.groupName.toUpperCase();
          let textB = b.groupName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
        })
        this.selectedRestaurantGroup = this.preSelectedRestaurantGroup;
        this.previousSelectedRestaurantGroup = this.preSelectedRestaurantGroup;
      }

      this.changeRestaurant?.forEach((x: any) => {
        if (x.groupName ==  this.selectedRestaurantGroup) {
          this.availableModelingRestaurant.push(x);
        }
      })
      if (this.availableModelingRestaurant?.length > 0) {
        this.selectedRestaurantNumber = (this.preSelectedRestaurantNumber);
        this.previousSelectedRestaurantNumber = this.preSelectedRestaurantNumber;
      }
      
       this.ChangeRestaurantNum(this.selectedRestaurantNumber);
    this.restaurantSelectForm = this.fb.group({
      restaurantNums: new FormControl(''),
      searchText: new FormControl('')
    });

    this.restaurantSelectForm.valueChanges.subscribe(val => {
      this.getSearchText(val.searchText);
    })
  }
 
  getGroupSearchText(text: string) {
    if (this.groupSearchText?.length == 0) {
      return text;
    } else {
      const str = text + '';
      var regex = new RegExp(this.groupSearchText, "gi");
      return str.replace(regex, function (matched) { return "<strong>" + matched + "</strong>"; })
    }
  };


  getSearchText(num: string) {
    num = num.replace(/\,/g,'')
    if (this.searchText?.length == 0) {
      return num;
    } else {
      const str = num + '';
      var regex = new RegExp(this.searchText, "gi");
      return str.replace(regex, function (matched) { return "<strong>" + matched + "</strong>"; })
    }
  };
formatNumbers(vals:any[]) {
  this.numberSelected = 0;
  if(vals.indexOf(0)>=0) {
    this.numberSelected = this.availableModelingRestaurant.length;
    return "All";
  } 
  let nums = vals.map(ele=>{
    const len = ele.toString().length;
    const numZeros = 6-len;
    let zeroes='';
    for(var i=0; i< numZeros; i++) {
      zeroes+="0";
    }
    return zeroes+ele;
  });
  this.numberSelected = this.restaurantSelectForm.controls['restaurantNums'].value?.length;
  return nums
}

  refreshRestaurantNum(groupName) {
    if(this.canChange){
      this.availableModelingRestaurant = [];
    this.changeRestaurant.forEach((x: any) => {
      if (x.groupName == groupName) {
        this.availableModelingRestaurant.push(x);
      }
    });
    this.selectedRestaurantGroup = groupName;
    this.previousSelectedRestaurantGroup = groupName;
    this.selectedRestaurantNumber = (this.availableModelingRestaurant[0].restaurantNum);
    this.ChangeRestaurantNum(this.selectedRestaurantNumber);
    // this.OnRestaurantGroup.emit(groupName);
    }else{
      setTimeout(()=>{
        this.selectedRestaurantGroup= this.previousSelectedRestaurantGroup;
      },100);
      this.changedRes = false;
      this.groupName = groupName;
      this.modalService.open(this.mymodal)
    }
  }

  ChangeRestaurantNum(value) {
    if(this.canChange){
    // this.OnRestaurantNum.emit(value);
    this.selectedRestaurantNumber = value;
    this.previousSelectedRestaurantNumber = this.selectedRestaurantNumber;
    }else{
      setTimeout(() => {        
        this.selectedRestaurantNumber = this.previousSelectedRestaurantNumber;
      }, 100);
      this.changedRes = true;
      this.value = value;
      this.modalService.open(this.mymodal);
    }
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.restaurantSelectForm.controls['restaurantNums']
        .patchValue([...this.availableModelingRestaurant.map(item => {
          return item.restaurantNum;
        }), 0]);
    } else {
      this.restaurantSelectForm.controls['restaurantNums'].patchValue([]);
    }
  }

  togglePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();

    }
    if (this.restaurantSelectForm.controls['restaurantNums'].value.length == this.availableModelingRestaurant.length - 1) {
     // this.allSelected.select();
    }
  }

  getSearchCtrl(form: any, items: any[]): any[] {
    const term = form.controls['searchText'].value;

    const exists = items.filter(item => {
      if (term?.length > 0) {
        return item?.restaurantNum?.toString().indexOf(term) >= 0;
      }
      return [];
    });
    return exists;
  }
  changeWithoutSave(){
    this.canChange=true;    
    this.modalService.dismissAll();
    this.sharedService.canChange.emit(true);
    if(this.changedRes){
      this.ChangeRestaurantNum(this.value);
    }else{
      this.refreshRestaurantNum(this.groupName);
    }
    
  }
  openSaveModel() {
    this.modalService.dismissAll();
    this.saveModalRef.openModal();
  }
}
