export const environment = {
  production: false,
  isTestEnvironment:false,
  isshowtab:true,
  competitorUrl:'https://rmp-rsiweb-dev.rsilink.com',
  restaurantUrl:'https://rmp-rsiweb-dev.rsilink.com',
  menuUrl:'https://rmp-rsiweb-dev.rsilink.com',
  rsiWebUrl:"https://rsiweb-dev.rsilink.com/Net/RMP",
  assetUrl:'https://rmp-rsiweb-dev.rsilink.com'
};

