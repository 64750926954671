<div class="row tab-content modeling-tab">

    <div class="col-md-4 row">
        <div class="col-md-6">
            <mat-label class="label-style">Restaurant Group</mat-label>
            <mat-select #group [(value)]="selectedRestaurantGroup" [disableOptionCentering]="true"
                (valueChange)="refreshRestaurantNumModeling(group.value)">
                <input type="text" class="search" placeholder="Search" (blur)="clearSearchText()" aria-label="Number"
                    [(ngModel)]="groupSearchText">
                <mat-option *ngFor="let val of availableModelingRestaurantGroup | groupFilter: groupSearchText" [value]="val.groupName">
                    <div [innerHTML]="getGroupSearchText(val.groupName)"></div>
                </mat-option>
            </mat-select>
        </div>
        <div class="col-md-6">
            <mat-label class="label-style">Restaurant #</mat-label>

            <mat-select #competitor [(value)]="selectedRestaurant"
            [disableOptionCentering]="true" id="restnum"
            (valueChange)="ChangeRestaurantNum(competitor.value)">
            <input type="text" class="search" placeholder="Search" (blur)="clearSearchText()"
                aria-label="Number" [(ngModel)]="searchText">
            <mat-option
                *ngFor="let val of availableModelingRestaurant | restaurantFilter: searchText"
                [value]="val.restaurantNum">
                <div
                    [innerHTML]="getSearchText(val.restaurantNum | number: '7.0-0')">
                </div>
                
            </mat-option>
        </mat-select>
        </div>
        <div class="col-md-12">
            <mat-label class="label-style">Model</mat-label>
            <mat-select #modelImpact id="impactModel" [disableOptionCentering]="true" [(value)]="impactModelSelected"
                (valueChange)="selectedModelValueChange(modelImpact.value)">
                <form [formGroup]="searchModelForm">
                    <input type="text" name="search" class="search w-100" placeholder="Search" aria-label="Number"
                        id="searchModel" formControlName="searchModelInputValue" (blur)="selectedModelValueChange()">
                </form>
                <mat-option *ngFor="let modelListV of modelList" [value]="modelListV.name">
                    {{modelListV.name}} ({{formatDate(modelListV?.createdOn)}})
                </mat-option>
            </mat-select>
        </div>
        <div class="col-md-6 mt-2">
            <mat-label class="label-style">Price Change Date</mat-label>
            <!-- <input type="date" class="impact-date" [(ngModel)]="impactDate" (click)="priceChangeDate($event)"> -->
            <div class="input-group">
                <input
                    class="form-control"
                    placeholder="yyyy-mm-dd"
                    name="dp"
                    [(ngModel)]="impactDate"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    (change)="priceChangeDate($event)"
                    (dateSelect)="priceChangeDate($event)"
                    [markDisabled]="canPickDate"
                />
                <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
            </div>
        </div>
        <div class="col-md-6 mt-2">
            <mat-label class="label-style">Impact Period</mat-label>
            <mat-select #impactPeriod [disableOptionCentering]="true" id="impactPeriod" name="period" ngDefaultControl  [(ngModel)]="impactPeriodValue"
                (valueChange)="selectImpactPeriod(impactPeriod.value)">
                <mat-option *ngFor="let impactPeriodV of impactPeriodList" [value]="impactPeriodV.id"
                    [disabled]="!periodIsBeforeCurrentDate(impactPeriodV.name)">
                    {{impactPeriodV.name}}
                </mat-option>
            </mat-select>
        </div>

    </div>


    <div class="col modedlingSales">
        <app-sales-cards [title]="monthCardDetails?.title" [overallSales]="monthCardDetails.overallSales"
            [overallUnits]="monthCardDetails.overallUnits" [overallGP]="monthCardDetails.overallGP" [showCard]="true"
            [isModelling]="false" [actualUnits]="monthCardDetails?.actualUnits"
            [actualSales]="monthCardDetails?.actualSales" [actualGp]="monthCardDetails?.actualGp"
            [unitsDiff]="monthCardDetails.unitsDiff" [salesDiff]="monthCardDetails.salesDiff"
            [gpDiff]="monthCardDetails.gpDiff" [showImpact]="true"></app-sales-cards>
    </div>
    <div class="col modedlingSales">
        <app-sales-cards [title]="yearCardDetails.title" [overallSales]="yearCardDetails.overallSales"
            [overallUnits]="yearCardDetails.overallUnits" [overallGP]="yearCardDetails.overallGP" [showCard]="true"
            [isModelling]="false" [actualUnits]="yearCardDetails?.actualUnits"
            [actualSales]="yearCardDetails?.actualSales" [actualGp]="yearCardDetails?.actualGp"
            [unitsDiff]="yearCardDetails.unitsDiff" [salesDiff]="yearCardDetails.salesDiff"
            [gpDiff]="yearCardDetails.gpDiff" [showImpact]="true"></app-sales-cards>
    </div>
    <!-- <div class="col">
            <app-sales-cards [title]="mtitle" [salesDiff]="monthSalesDiff" [unitsDiff]="monthUnitsDiff"
                [gpDiff]="monthGpDiff" [showCard]="true" [isModelling]="false" [isLoading]="loadingCards" [showImpact]="true">
            </app-sales-cards>
        </div>

        <div class="col">
            <app-sales-cards [title]="ytitle" [salesDiff]="yearSalesDiff" [unitsDiff]="yearUnitsDiff"
            [gpDiff]="yearGpDiff" [showCard]="true" [isModelling]="false" [isLoading]="loadingCards" [showImpact]="true">
            </app-sales-cards>
        </div> -->


</div>
<ng-container>
    <div style="margin-top: 20px;padding:0%">
        <app-impact-pricing-table [restaurantNum]="selectedRestaurant" [impactDate]="impactDate" [impactPeriodValue]="impactPeriodValue" [impactModelSelectedPeriod]="impactModelSelectedPeriod" [loadSpinner]="loadSpinner"
        [impactModelSelected]="impactModelSelected" [dummyItems]="dummyItems" [menuItems]="menuItems" (dateRange)="dateRangeMenu($event)" [pluLength]="pluLength" [timePeriodSelected]="timePeriodSelected"></app-impact-pricing-table>
    </div>
</ng-container>

<ng-template #notSavedModel let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Warning</h4>
        <i class="bi bi-x-circle delete-cross" style="cursor: pointer;" (click)="modal.dismiss('cross')"></i>
    </div>
    <div class="modal-body">
        <div class="inner-body">
            <p class="error-message">
                You must first save a model to be able to view the impact module. Please visit the modeling tab to save
                a model first.
            </p>
        </div>
    </div>

</ng-template>