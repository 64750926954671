import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { TokenService } from '../services/token.service';

export interface AuthWindow extends Window {
  userTK: string;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  windowRef: Window;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(@Inject('Window') window: Window, public router: Router, private tokenService: TokenService) {  
    this.windowRef = window;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
        'Authorization': `Bearer ${(this.windowRef as any).userTK}`,
      },
    });

    return next.handle(req).pipe(catchError(error => {
      console.log('handle auth error ',JSON.stringify(error?.error))
      if (error instanceof HttpErrorResponse &&  error.status === 401) {
        return this.handle401Error(req, next);
      }
      return throwError(error);
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = (this.windowRef as any).userTK;
      const self = this;
      this.tokenService.refreshAuthToken(token).pipe(
        switchMap((newToken: string) => {
          const tk = newToken['d'];
          self.isRefreshing = false;
          (self.windowRef as any).userTK = tk;
          self.refreshTokenSubject.next(tk);          
          return next.handle(self.addTokenHeader(request, tk));
        }),
        catchError((msg) => {
          self.isRefreshing = false;

          if (msg.status == '403') {
            self.router.navigate(['error']);
          }

          return throwError(() => msg);
        })

      ).subscribe((result)=>{console.log('refreshed token')});     
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
  }
}