<tr class="mat-loading" *ngIf="isLoading">
  <td colspan="5">
    <div class="d-flex justify-content-center align-items-center">
      <mat-spinner></mat-spinner>
    </div>
  </td>
</tr>

<!-- Newest Price -->
<tr class="matloading" *ngIf="isLoading">
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
</tr>
<tr *ngIf="!isLoading">
  <td *ngIf="!tableData || tableData.length ===0" colspan="5" class="no-data">

    <p *ngIf="compResIds.length>0">Sorry no data exists for restaurant <strong>#{{selectedRestaurantNumber}}</strong>
    </p>
    <p *ngIf="compResIds.length==0">Sorry no Competitor data exist for restaurant
      <strong>#{{selectedRestaurantNumber}}</strong> </p>
  </td>
</tr>
<ng-container *ngIf="!isLoading && header != 'Change in Price (USD)' && header != 'Change in Price (%)'  ">
  <span class="radio-button" *ngIf="subCategory=='Whopper' && tableData.length>0">
    <label class="radio-label">
      <input type="radio" [(ngModel)]="radioSelected" (change)="checkRadioButton()" value="QPC">&nbsp; QPC
    </label>
    <label class="radio-label">
      <input type="radio" [(ngModel)]="radioSelected" (change)="checkRadioButton()" value="bigMac">&nbsp; Big Mac
    </label>
  </span>
  <tr *ngIf="tableData?.length>0">
    <th *ngFor="let row of tableData[0]; let i = index;">
      <a class="alert-btn" (click)="save(i)" id="saveBtn" *ngIf="!editMode[i]"><img src="/assets/Enter.png">Enter
        Data</a>
      <a class="alert-btn" *ngIf="editMode[i]" id="saveBtn" (click)="saveChanges(i)"><img src="/assets/Enter.png">Save
        changes</a>
    </th>
  </tr>
  <ng-container *ngIf="tableData?.length>0">
    <tr *ngFor="let row of tableData;let i = index;">
      <td *ngFor="let data of row; let j=index"
        [ngClass]="{'shaded-cell':i%2===0, 'no-data':(data?.noItemPrice || !((j==0 && data?.name) || (j!=0 && data?.competitorPluName)))}">
        <span class="mx-2" *ngIf="(j!=0)&&(!data?.competitorPluName|| data?.competitorPluName==null)"><i
            style="padding-left: 5px; font-size: 14px;" class="bi bi-slash-circle text-danger"></i></span>
        <div class="row horz-center-items" *ngIf="(data?.competitorPluName && data?.name) || j==0 ">
          <span class="col-7 menuItemName" *ngIf="j==0"> {{j==0?data.name:data.competitorPluName}} </span>
          <span class="col-7 menuItemName" *ngIf="j!=0"> {{j==0?data.name:data.competitorPluName}}
            {{j==0?data.size:data.size}}</span>

          <span class="col-5 menuItemName-price" *ngIf="!editMode[j]"><span
              *ngIf="data?.competitorPluName && data?.name  || j==0">
              <strong *ngIf="data.price !=0 && data.price !=null">${{data.price | number: '1.2-2'}}</strong>
              <strong *ngIf="data.price ==0 || data.price ==null"><i
                  class="bi bi-exclamation-triangle-fill text-warning"></i></strong>
              <span (mouseenter)="selectMenuItem(data)" [ngbTooltip]="priceTipContent" #priceTooltip="ngbTooltip"
                [closeDelay]="800" [autoClose]="true">
                <ng-template #priceTipContent>
                  <div (mouseleave)="priceTooltip.close()"> 
                    <!-- <span *ngIf ="data.priceCount == 1">Collected
                    {{data.priceExtractedDt |
                    date: 'MM/dd/yy' }} at
                    {{data.priceExtractedDt | date: 'hh:mm aa'}}</span> -->
                  <div class="d-flex flex-column tooltip-container" >
                      <span>Entered: {{data.priceExtractedDt | date: 'MM/dd/yy' }}</span>
                      <span>Time: {{data.priceExtractedDt | date: 'hh:mm aa'}}</span>
                      <span>Changed By: {{data.priceSourceName=='pipeline'? 'System Provided':
                        "Manually Entered"}}</span>
                      <div class="d-flex justify-content-between" *ngIf ="data.priceCount > 1">
                        <span>Previous Price: $ {{menuItemPreviousPrice[1]?.price ? (menuItemPreviousPrice[1]?.price |
                          number:
                          '1.2-2'): 0.00 | number: '1.2-2' }}</span>
                        <span *ngIf="menuItemPreviousPrice.length>2"
                          style="text-align:right;color:#97C0FE;text-decoration: underline;background-color: transparent; cursor:pointer;"
                          (click)="OpenPriceHistoryModel(priceHistoryModel,data)">See History</span>
                      </div>
                      <div class="d-flex justify-content-between" *ngIf="data.priceCount == 1">
                        <span>
                          No previous price available
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <span class="pricetooltip"
                *ngIf="data.priceSourceName == 'pipeline' && data.price !=0"><i
                  class="bi bi-info-circle"></i></span><span
                *ngIf="data.priceSourceName != 'pipeline' && data?.price!=0"
                  class="pricetooltip"><img src="{{assetsUrl}}/assets/entered_hand.svg" /></span></span>
            </span></span>

          <span class="col-5 d-flex justify-content-end" *ngIf="editMode[j]">
            <input #newPrice type="number" *ngIf="j==0 ? data?.name : (data?.competitorPluName && data?.name) "
              value="{{data.price}}" (change)="updatePrice(data,newPrice.value,i,j)" onkeypress='return event.charCode != 101 && event.charCode != 69' (value)="data.price" step=".01"
              id="PriceInput{{i}}{{j}}" (keydown.delete)="onPressDeleteKey($event,data,i,j)"
              [style.color]="data.price === data.price  ? '#272E3B':'#1B53C5'" style="width: 50px;">
          </span>
          <span class="col-5" *ngIf="!editMode[j] && !inside">
            <strong *ngIf="data.price !=0 && data.price !=null && inside">${{data.price | number: '1.2-2'}}</strong>
          </span>
        </div>
      </td>
      <td *ngFor="let data of [].constructor(5 - row?.length)" [ngClass]="{'shaded-cell':i%2===0}">
        <span class="mx-2" style="color: #272E3B66;"> Menu Item <strong>$ 0.00</strong></span>
      </td>
    </tr>
  </ng-container>
</ng-container>

<!-- Change in Price(USD) -->

<ng-container *ngIf="!isLoading && header == 'Change in Price (USD)'">
  <tr *ngIf="tableData?.length>0">
    <th *ngFor="let row of tableData[0];let i = index;">
      <a class="alert-btn has-alerts" *ngIf="i!==0 && colHasAlerts(row, tableData[0], i, false)"
        (click)="openSetupAlert(setupAlert,row, i, false)"><i class="bi bi-bell"></i>See Alert(s)</a>

      <a class="alert-btn" *ngIf="i!==0 && !colHasAlerts(row, tableData[0], i, false)"
        (click)="openSetupAlert(setupAlert,row, i, false)"><i class="bi bi-bell"></i>Set up Alert</a>
      <span *ngIf="i===0">&nbsp;</span>
    </th>
  </tr>
  <ng-container *ngIf="tableData?.length>0">
    <tr *ngFor="let row of tableData;let i = index;">
      <td *ngFor="let data of row;let j=index"
        [ngClass]="{'shaded-cell':i%2===0, 'no-data':(data?.noItemPrice || !((j==0 && data?.name) || (j!=0 && data?.competitorPluName)))}">

        <div *ngIf="(j==0 && data?.name) || (j!=0 && data?.competitorPluName)" class="row horz-center-items">
          <span class="col-7 menuItemName" *ngIf="j==0"> {{j==0?data.name:data.competitorPluName}} </span>
          <span class="col-7 menuItemName" *ngIf="j!=0"> {{j==0?data.name:data.competitorPluName}}
            {{j==0?data.size:data.size}}</span>

          <span class="col-5 d-flex align-items-center justify-content-end menuItemName-price">
            <strong class="red" *ngIf="data.changeInUSDPrice<0">
              $ {{data.changeInUSDPrice | number: '1.2-2'}}
              <i class="bi bi-arrow-down"></i>
            </strong>
            <strong class="green" *ngIf="data.changeInUSDPrice>0">
              + $ {{data.changeInUSDPrice | number: '1.2-2'}}
              <i class="bi bi-arrow-up"></i>
            </strong>
            <strong class="" *ngIf="!data.changeInUSDPrice || data.changeInUSDPrice===0">
              No Change
            </strong>
            <span *ngIf="data.changeInUSDPrice<0 || data.changeInUSDPrice>0" style="padding-left: 5px;">
              <ng-container *ngIf="j==0">
                <i class="bi bi-info-circle" [ngbTooltip]="tipContent"></i>
                <ng-template #tipContent>Collected {{data.priceExtractedDt | date: 'MM/dd/yy' }} at
                  {{data.priceExtractedDt | date: 'hh:mm
                  aa'}}</ng-template>
              </ng-container>
              <ng-container *ngIf="j!=0">
                <i class="bi bi-info-circle" [ngbTooltip]="tipContentA"></i>
                <ng-template #tipContentA>Aggregated {{data.priceExtractedDt | date: 'MM/dd/yy' }} at
                  {{data.priceExtractedDt | date: 'hh:mm
                  aa'}}</ng-template>
              </ng-container>

            </span>
          </span>


        </div>
        <span class="mx-2" style="font-size: 14px;"
          *ngIf="data?.noItemPrice || !((j==0 && data?.name) || (j!=0 && data?.competitorPluName))"><i
            class="bi bi-slash-circle text-danger"></i></span>
      </td>
      <td *ngFor="let data of [].constructor(5-row.length)" [ngClass]="{'shaded-cell':i%2===0}">
        <span class="mx-2" style="color: #272E3B66;"> Menu Item <strong>$ 0.00</strong></span>
      </td>
    </tr>
  </ng-container>
</ng-container>

<!-- Change in Price(%) -->

<ng-container *ngIf="!isLoading && header == 'Change in Price (%)'">
  <ng-container *ngIf="tableData?.length>0">
    <tr *ngFor="let row of tableData;let i = index;">

      <td *ngFor="let data of row; let j = index"
        [ngClass]="{'shaded-cell':i%2===0, 'no-data':(data?.noItemPrice || !((j==0 && data?.name) || (j!=0 && data?.competitorPluName)))}">
        <div *ngIf="(j==0 && data?.name) || (j!=0 && data?.competitorPluName)" class="row horz-center-items">
          <span class="col-7 menuItemName" *ngIf="j==0"> {{j==0?data.name:data.competitorPluName}} </span>
          <span class="col-7 menuItemName" *ngIf="j!=0"> {{j==0?data.name:data.competitorPluName}}
            {{j==0?data.size:data.size}}</span>
          <span class="col-5 d-flex align-items-center">
            <strong class="red" *ngIf="data.changeInPercentagePrice<0">
              {{data.changeInPercentagePrice | number: '1.2-2'}}%
              <i class="bi bi-arrow-down"></i>
            </strong>
            <strong class="green" *ngIf="data.changeInPercentagePrice>0">
              +{{data.changeInPercentagePrice | number: '1.2-2'}}%
              <i class="bi bi-arrow-up"></i>
            </strong>
            <strong *ngIf="!data.changeInPercentagePrice || data.changeInPercentagePrice==0">
              No Change
            </strong>
            <span *ngIf="data.changeInPercentagePrice<0 || data.changeInPercentagePrice>0" style="padding-left: 1px;">


              <ng-container *ngIf="j==0">
                <i class="bi bi-info-circle" [ngbTooltip]="tipContent"></i>
                <ng-template #tipContent>Collected {{data.priceExtractedDt | date: 'MM/dd/yy' }} at
                  {{data.priceExtractedDt | date: 'hh:mm
                  aa'}}</ng-template>
              </ng-container>
              <ng-container *ngIf="j!=0">
                <i class="bi bi-info-circle" [ngbTooltip]="tipContentA"></i>
                <ng-template #tipContentA>Aggregated {{data.priceExtractedDt | date: 'MM/dd/yy' }} at
                  {{data.priceExtractedDt | date: 'hh:mm
                  aa'}}</ng-template>
              </ng-container>
            </span>
          </span>

        </div>
        <span class="mx-2" style="font-size: 14px;"
          *ngIf="data?.noItemPrice || !((j==0 && data?.name) || (j!=0 && data?.competitorPluName))"><i
            class="bi bi-slash-circle text-danger"></i></span>
      </td>
      <td *ngFor="let data of [].constructor(5-row.length)" [ngClass]="{'shaded-cell':i%2===0}">
        <span class="mx-2" style="color: #272E3B66;"> Menu Item <strong>$ 0.00</strong></span>
      </td>
    </tr>
  </ng-container>
</ng-container>


<ng-template #setupAlert let-modal>
  <div *ngIf="showAlertConfig && !showSuccessDelete">
    <div class="modal-header">

      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="d-flex justify-content-center">
      <h4 class="modal-title" id="modal-basic-title"><i class="bi bi-bell"></i> Set Up Alert</h4>
    </div>
    <div class="d-flex justify-content-center">

      <p>at {{brandNotification}} <strong>{{brandAddress}}</strong></p>
    </div>
    <div class="modal-body">
      <div class=" alert-body">
        <div class="d-flex justify-content-center" *ngIf="formAlertItems.controls?.length==0">
          Sorry there is no price to watch for this item.
        </div>
        <form [formGroup]="itemAlertForm" *ngIf="formAlertItems.controls?.length>0">
          <div formArrayName="alertItems">
            <div class="row">
              <div class="col-1  d-flex align-items-center justify-content-center">
                Delete
              </div>
            </div>
            <div class="row alertItemRow" *ngFor="let item of formAlertItems.controls; let i =index;">
              <ng-container [formGroupName]="i">
                <div class="col-1 d-flex align-items-center justify-content-center forText">
                  <input class="form-check-input" formControlName="deleteItem" type="checkbox">
                </div>

                <div class="col-11 d-flex align-items-center justify-content-center forText">
                  For <span class="alert-field">
                    <select style="width:205px" class="form-control" formControlName="competitorPluId"
                      id="competitorPluId{{i}}">
                      <option [ngValue]="null">Select Menu Item</option>
                      <option *ngFor="let item of alertItems" [ngValue]="item.competitorPluId">
                        {{item.competitorPluName}} {{item.size}}
                      </option>
                    </select>
                  </span>
                  <span>when price</span>
                  <span class="alert-field">
                    <select class="form-control" formControlName="isIncreased" id="isIncrease{{i}}">
                      <option [ngValue]="null">Select price variable</option>
                      <option [value]="true">Increases</option>
                      <option [value]="false">Decreases</option>
                    </select>
                  </span>
                  by <span class="alert-field" style="width:90px">
                    <div class="input-group" *ngIf="!showSetupAlertPercentage">
                      <span class="input-group-text">$</span>
                      <input class="form-control" formControlName="changeInPrice" placeholder="0.00"
                        (input)="numberOnly($event)" (keypress)="numberOnly($event)" />

                    </div>

                    <div class="input-group" *ngIf="showSetupAlertPercentage">
                      <input class="form-control" formControlName="changeInPrice" placeholder="5"
                        (input)="numberOnly($event)" (keypress)="numberOnly($event)" />
                      <span class="input-group-text">%</span>
                    </div>
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="error" *ngIf="setupAlertError">
            Please or enter values in each item row.
          </div>
          <a class="addAlertLink" (click)="addAlertItem({menuitemId:null,changeInPrice:null})">+Add Alert</a>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn cancel-btn" style="padding:initial" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button *ngIf="formAlertItems.controls?.length>0" type="button" style="padding:initial" class="btn set-btn"
        (click)="continueAlerts()">Continue</button>
    </div>
  </div>
  <div *ngIf="!showAlertConfig && !showSuccessDelete">
    <div class="modal-header">

      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="d-flex justify-content-center">

      <p><strong><i class="bi bi-bell"></i> Save Alert</strong></p>
    </div>
    <div class="modal-body">
      <div class=" row">
        <div class="col-12">
          Your alert(s) will be sent to the email below:
        </div>

      </div>
      <div class=" row">
        <div class="col-6">
          <input class="form-control" [(ngModel)]="alertEmail" />
          <div class="error" *ngIf="showInvalidEmailMsg">
            Please enter a valid email address.
          </div>

        </div>
      </div>
      <br />
      <div class=" row">
        <div class="col-12">
          Your alert(s) will be sent to the mobile number below:
        </div>
      </div>
      <div class=" row">
        <div class="col-6">
          <input class="form-control" [(ngModel)]="alertPhone" [textMask]="{mask: phoneMask}" type="text" />
          <div class="error" *ngIf="showInvalidPhoneMsg">
            Please enter a valid mobile phone number.
          </div>
          <div class="" *ngIf="alertsSaved">
            Alert has been saved successfully.
          </div>
        </div>
      </div>
    </div>


    <div class="modal-footer">
      <button class="btn cancel-btn" style="padding:initial" (click)="modal.dismiss('Cross click')">Close</button>
      <button *ngIf="formAlertItems.controls?.length==0" type="button" style="padding:initial" class="btn set-btn"
        (click)="saveAlerts()">Set up Alert(s)</button>
      <button *ngIf="formAlertItems.controls?.length>0" type="button" [disabled]="disableSave" style="padding:initial"
        class="btn set-btn" (click)="saveAlerts()">Save</button>
    </div>
  </div>
  <div *ngIf="showSuccessDelete">
    <div class="modal-header">
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="d-flex justify-content-center">

      <p><strong><i class="bi bi-bell"></i> Delete alert</strong></p>
    </div>

    <div class="modal-body">
      <div class=" row">
        <div class="col-12">
          Your alert(s) have been deleted. You will no longer receive messages about this price change.
        </div>

      </div>
    </div>

    <div class="modal-footer">
      <button class="btn cancel-btn" style="padding:initial" (click)="modal.dismiss('Cross click')">Close</button>

    </div>
  </div>
</ng-template>

<ng-template #priceHistoryModel let-modal>
  <div class="historyModal-container">
    <div style="width:100%;text-align: right;">
      <i class="bi bi-x" (click)="modal.dismiss('Cross click')" style="font-size: 1.5rem;cursor: pointer;"></i>
    </div>
    <div class="title d-flex flex-column align-items-center">
      <div class="d-flex flex-row">
        <img src="{{assetsUrl}}/assets/price-logo.png" height="21.81px" width="22.23px">
        <p class="title-header">Price Change History</p>
      </div>
      <p style="font-size: 18px;padding-bottom:0px;margin-bottom: 0px;">{{menuItemPreviousPrice[0]?.competitorName ?
        menuItemPreviousPrice[0]?.competitorName : 'BK'}} #{{(menuItemPreviousPrice[0]?.restaurantNum | number :
        '6.0-0').replace(',','')}}</p>
      <p class="title-header">{{menuItemPreviousPrice[0]?.competitorPluName}} {{menuItemPreviousPrice[0]?.size}}</p>
    </div>
    <div style="height:240px">
      <table class="table priceHistory" style="border-collapse:collapse">
        <thead>
          <tr>
            <th>
              Entered By
            </th>
            <th>
              Date
            </th>
            <th>
              Time
            </th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of [].constructor(menuItemPreviousPrice.length); let i=index">
            <th>
              {{menuItemPreviousPrice[i].priceSourceName == 'pipeline'? 'System Provided':
              "Manually Entered" }}
            </th>
            <th>
              {{menuItemPreviousPrice[i].priceExtractedDt | date: 'MM/dd/yyyy' }}
            </th>
            <th>
              {{menuItemPreviousPrice[i].priceExtractedDt | date: 'hh:mm aa'}}
            </th>
            <th>
              $ {{menuItemPreviousPrice[i]?.price | number: '1.2-2'}}
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer d-flex align-items-end">
      <button class="btn cancel-btn" style="padding:initial" (click)="modal.dismiss('Cross click')">Cancel</button>
    </div>
  </div>

</ng-template>

<div class="overlay" *ngIf="showError">

  <div class="popup">

    <div class="content">
      <a class="close" (click)="showError = false">&times;</a>
      <h1 class="letter" style="font-size: 30px;">Error</h1>

      <h2 class="letter">{{errorMsg}}</h2>
    </div>

  </div>

</div>