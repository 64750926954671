<ng-template #manageModal let-modal>
    <div class="modal-header row">
        <div class="d-flex justify-content-center col-11">
            <h4 class="modal-title">Projected % Change</h4>
        </div>
        <button type="button" class="btn-close col-1" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body"
        style="width: 550px;font-family: 'Lato';font-style: normal;font-weight: 400;font-size: 14px;">
        <!-- <span>I Project my AUVs will increase by 10%</span> -->
        <div class="col-11 d-flex align-items-center justify-content-center forText">

            <span style="margin-right: 5px;">I assume my AUVs will</span>
            <span class="alert-field">
                <select class="form-control" [(ngModel)]="tempPriceIncrease">
                    <option value=''>Select price variable</option>
                    <option value="true">Increase</option>
                    <option value="false">Decrease</option>
                </select>
            </span>
            <span style="padding: 0 5px;">
                by
            </span>
            <span class="alert-field" style="width:120px">
              <div class="input-group">
                <span class="input-group-text">%</span>
                <input class="form-control" placeholder="0.00" [(ngModel)]="tempPriceChangePercentage"
                  />

                </div>
            </span>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" type="close" class="btn cancel-btn" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn set-btn" (click)="EnterProjectionChange()">Continue</button>
    </div>
</ng-template>
<div class="mat-loading" *ngIf="isLoading">
    <div>
        <div class="d-flex justify-content-center align-items-center">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
<div class="box2">
    <div class="row">
        <div *ngIf="tableData && tableData?.length !==0 && !isLoading" class="col"
            style="margin-left: 15px; margin-top: 5px;">
            <strong>{{removeComma(currentRes.num | number: '7.0-0') }}-{{savedDate | date:
                'M.d.yy'}}-{{modelVersion}}</strong><span *ngIf="!isSaved"
                style="color: #8E96A7;font-style: italic;">-Unsaved</span><span *ngIf="isSaved"
                style="color: #8E96A7;font-style: italic;">-Saved</span>
        </div>
        <div *ngIf="competitors?.length==0 && !isLoading" class="ps-4 error">* No Competitor data exist for restaurant &nbsp;<strong>#{{currentRes.num}}</strong></div>
        <div *ngIf="!isLoading" class="col d-flex justify-content-end align-items-center" style="margin-top:5px"
            style="position: absolute;right:15px; margin-top: 5px;">
            <span *ngIf="tableData && tableData?.length !==0 " style="position: relative;margin:0 5px">
                <!-- (click)="loadButtons(menuItems[0].RbiPriceLoaded,menuItems[0].categoryId)" -->
                <button [disabled]=" true" type="button" class="alert-btn disable-button">Load RBI
                    Recommendation</button>
                <br> <span *ngIf="false" style="font-size: 10px;position:absolute">Last collected on 5/30/2023</span>
            </span>
            <span *ngIf="tableData && tableData?.length !==0 " style="margin:0 5px">
                <button type="button" class="alert-btn" [ngClass]="{'ShowingPrice':projectionFlag}"
                    (click)="openManageModel(manageModal)">{{projectionValue}}</button>
            </span>
            <div *ngIf="isServiceLoaded" style="padding:0px 5px">
                <mat-button-toggle-group #group="matButtonToggleGroup" [value]="selectedServiceModeId"
                    (change)="ChangeServiceMode(group.value)">
                    <mat-button-toggle [ngClass]="{'inStore':selectedServiceModeId===availableServiceMode[0]?.id}"
                        [value]="availableServiceMode[0]?.id">{{availableServiceMode[0]?.name}}</mat-button-toggle>
                    <mat-button-toggle [ngClass]="{'thirdp':selectedServiceModeId===availableServiceMode[1]?.id}"
                        [value]="availableServiceMode[1]?.id">{{availableServiceMode[1]?.name}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
    </div>
    <div *ngIf="!tableData || tableData?.length ===0 && !isLoading"
        class="no-data d-flex justify-content-center align-items-center">
        <p>Sorry no data exists for restaurant <strong>#{{currentRes.num}}</strong> with <strong>selected
                category</strong> </p>
    </div>
    <table style="margin-top:30px;width:100%" *ngIf="tableData && tableData?.length !==0 && !isLoading">
        <tr>
            <th >
            </th>
            <th>
                <div>
                    <div class='d-flex align-items-center justify-content-center heading-cell'>Menu Price ($)</div>
                    <div class="row sub-cloumn">
                        <span class="col sub-column-text" style="width: fit-content;">
                            <span>
                                <img class="comp-img" style="width:20px" [src]="getIcon('BK')" />
                            </span> &nbsp;&nbsp;
                            <span class="d-flex align-items-center">#{{removeComma(currentRes.num| number :
                                '7.0-0')}}</span>
                        </span>
                        <span class="row" [ngClass]="{'col-4':competitors?.length==1, 'col-5':competitors?.length!=1}"
                            *ngIf="checkedCompetitors && competitors?.length!==0" >
                            <span class="col sub-column-text" style="width:20px;padding:0px 2px"
                                *ngFor="let comp of competitors">
                                <div class="row ml-2">
                                    <div class="col-3 ">
                                        <img class="comp-img" style="width:20px" [src]="getIcon(comp.competitorName)" />
                                    </div>
                                    <div class="col-9 d-flex align-items-center">
                                        <span *ngIf="!isTestEnv"> #{{removeComma(comp.num| number :
                                            '7.0-0')}}</span>
                                        <span *ngIf="isTestEnv"> ******</span>
                                    </div>
                                    <div class=" comp-info-col">
                                        <span>
                                            <span *ngIf="comp.competitorName" class="distance">{{comp.distance| number :
                                                '1.1-1'}} Miles
                                                Away</span>
                                        </span>

                                    </div>
                                </div>
                            </span>
                        </span>
                        <span class="col sub-column-text disable-button"
                            >Recommended</span>
                        <span class="col sub-column-text">New Price</span>
                    </div>
                </div>
            </th>
            <th>
                <div class='d-flex align-items-center justify-content-center heading-cell'> AUVs Per Day (Units) </div>
                <div class="row sub-cloumn">
                    <span class="col sub-column-text">{{auvsFor}}</span>
                    <span class="col sub-column-text">Projected</span>
                </div>
            </th>
            <th>
                <div class='d-flex align-items-center justify-content-center heading-cell'>Sales ($)</div>
                <div class="row sub-cloumn">
                    <span class="col sub-column-text">Current</span>
                    <span class="col sub-column-text">Projected</span>
                </div>
            </th>
            <th>
                <div class='d-flex align-items-center justify-content-center heading-cell'>Gross Profit ($)</div>
                <div class="row sub-cloumn">
                    <span class="col sub-column-text">Current</span>
                    <span class="col sub-column-text">Projected</span>
                </div>
            </th>
        </tr>
        <tr *ngFor="let item of tableData; let i = index" [ngClass]="{'shaded-cell':i%2===1}" class="p-0">
            <td  style="background-color: white; text-align: right; word-wrap: break-word;  max-width: 160px; width: 150px;     white-space: break-spaces;">
              
                  <span>      {{item.pluName}}</span>
                    
              
            </td>
            <td>
                <div class="row d-flex justify-content-center align-items-center">
                    <span class="col sub-column-price">${{item.pluPrice | number:'0.2-2'}}</span>
                    <span class="row" [ngClass]="{'col':competitors?.length==1, 'col-5':competitors?.length!=1}"
                        *ngIf="checkedCompetitors">
                        <span class="col sub-column-text" style="width:150px"
                            *ngFor="let comp of competitors;let j= index">
                            <span *ngIf="item[comp.id] != -1 && item[comp.id] !=0">${{item[comp.id]}}</span>
                            <span *ngIf="item[comp.id]==0"><i
                                    class="bi bi-exclamation-triangle-fill text-warning"></i></span>
                            <span *ngIf="item[comp.id] == -1"><i class="bi bi-slash-circle text-danger"></i></span>
                        </span>
                    </span>
                    <span class="col"></span>
                    <!-- <span *ngIf="menuItems[0].RbiPriceLoaded " class="col sub-column-price"><span style="font-weight:bold" *ngIf="item.recomendedRBIPrice && item.recomendedRBIPrice>0">{{item.RbiPriceLoaded ? '$'+ item.recomendedRBIPrice : ''}}</span><span class="mx-2" *ngIf="!item.recomendedRBIPrice || item.recomendedRBIPrice==0">No Data<i style="padding-left: 5px;"
                        class="bi bi-exclamation-triangle-fill text-warning"></i></span></span> -->
                    <span class="col sub-column-price"><input class="inputBox"
                            #overridePrice [(ngModel)]="item.overridePrice" type="number" onkeypress='return event.charCode != 101 && event.charCode != 69'
                            (change)="onChangeprice(i,false,overridePrice.value)"></span>

                </div>
            </td>
            <td>
                <div class="row d-flex justify-content-center align-items-center">
                    <span class="col sub-column-price">{{(item.regUnits?(item.regUnits/pluLength | number:'0.2-2'):'')}}<i
                            *ngIf="!item.regUnits" class="bi bi-slash-circle text-danger"></i></span>
                    <span class="col sub-column-price"><input class="inputBox" #projectedRsiPrice type="number"
                            style="font-weight:bold" value="{{ (item.projectedUnitsCalculation) }}" onkeypress='return event.charCode != 101 && event.charCode != 69'
                            (keyup)="onChangeprice(i,true,projectedRsiPrice.value)" (change)="restrictDigit(i)"></span>
                </div>

            </td>
            <td>
                <div class="row d-flex justify-content-center align-items-center">
                    <span class="col sub-column-price"><span *ngIf="item.regAmt">$</span>{{item.regAmt?(item.regAmt | number:'0.2-2'):''}}<i
                            *ngIf="!item.regAmt" class="bi bi-slash-circle text-danger"></i></span>
                    <span class="col sub-column-price">{{ item.projectedAmt>=0 && item.projectedUnitsCalculation>=0?'$'+(item.projectedAmt |number:'0.2-2'):''}}</span>
                </div>
            </td>
            <td>
                <div class="row d-flex justify-content-center align-items-center">
                    <span class="col sub-column-price"><span *ngIf="item.gpAmt">$</span>{{item.gpAmt?(item.gpAmt| number:'0.2-2'):''}}<i
                            *ngIf="!item.gpAmt" class="bi bi-slash-circle text-danger"></i></span>
                    <span class="col sub-column-price">{{ item.gpProjectedAmt>=0 && item.projectedUnitsCalculation>=0?'$'+(item.gpProjectedAmt | number:'0.2-2'):''}} <i  *ngIf="item.gpProjectedAmt>=0 && item.projectedUnitsCalculation>=0" class="bi bi-info-circle" style="width:10px; height:10px;margin-left:5px;margin-bottom:5px" [ngbTooltip]="costAmt"></i></span>
                </div>

            </td>
            <ng-template #costAmt>
                <span>Cost of One Unit : ${{item.costofOneUnit | number:'0.2-2'}}</span>
            </ng-template>
        </tr>
    </table>
</div>