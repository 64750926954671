<div class="row">
    <div class="col-5">
        <mat-label class="competitors-label" id="competitorsLabelRestGrp">Restaurant Group</mat-label>
        <mat-select #group [disableOptionCentering]="true" [(value)]="selectedRestaurantGroup"
            (valueChange)="refreshRestaurantNum(group.value)" id="restaurantNum">
            <input type="text" class="search" placeholder="Search" [(ngModel)]="groupSearchText" aria-label="Number" id="searchRestName">
            <mat-option *ngFor="let val of availableModelingRestaurantGroup | groupFilter: groupSearchText"
                [value]="val.groupName">
                <div [innerHTML]="getGroupSearchText(val.groupName)"></div>
            </mat-option>
        </mat-select>
    </div>
    <div class="col-5">
        <mat-label class="competitors-label" id="restaurantNumSelected">Restaurant # <span *ngIf="isMultiSelect"class="badge bg-secondary">{{numberSelected}} </span></mat-label>
        <ng-container *ngIf="!isMultiSelect">
            <mat-select #competitor [disableOptionCentering]="true" [(value)]="selectedRestaurantNumber"
                (valueChange)="ChangeRestaurantNum(competitor.value)" id="selectRestNum">

                <input type="text" class="search" placeholder="Search" aria-label="Number" [(ngModel)]="searchText" id="searchRestNum">

                <mat-option *ngFor="let val of availableModelingRestaurant | restaurantFilter: searchText"
                    [value]="val.restaurantNum">
                    <div [innerHTML]="getSearchText(val.restaurantNum | number: '7.0-0')">
                    </div>
                </mat-option>
            </mat-select>
        </ng-container>


        <ng-container *ngIf="isMultiSelect">
            <form [formGroup]="restaurantSelectForm" autocomplete="off" id="restSelectFrom">
                <mat-select #competitor [disableOptionCentering]="true" [(value)]="selectedRestaurantNumbers"
                    [multiple]="true" id="restNumsSelect"
                    formControlName="restaurantNums">
                    <mat-select-trigger>
                      
                        {{formatNumbers(selectedRestaurantNumbers)}} 
                        
                    </mat-select-trigger>
                    <input type="text" class="search" placeholder="Search" aria-label="Number" id="searchRestNumsForm"
                        formControlName="searchText">

                    <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">All</mat-option>

                    <mat-option id="restNumsOpt"
                        *ngFor="let val of availableModelingRestaurant | callback: restaurantSelectForm : getSearchCtrl"
                        [value]="val.restaurantNum" (click)="togglePerOne(allSelected.viewValue)">
                        <div [innerHTML]="getSearchText((val.restaurantNum | number: '6.0-0').replace(',',''))">
                        </div>
                    </mat-option>
                </mat-select>
            </form>
        </ng-container>
    </div>
</div>
<app-save-model-modellingtool [modalPriceToSave]="modalPriceToSave" [selectedModelingRes]="preSelectedRestaurantNumber"></app-save-model-modellingtool>
<ng-template #notSavedModel let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Warning</h4>
      <i class="bi bi-x-circle delete-cross" (click)="changeWithoutSave()"></i>
  </div>
    <div class="modal-body">
        <div class="inner-body">
          <p class="error-message">
            Please save the model before continuing, otherwise changes will be lost
          </p>
        </div>
    </div>
    <div class="footerBtn mb-3">
        <button class="btn set-btn mx-3" (click)="openSaveModel()"><i
            class="bi bi-bookmark"></i>Save Models</button>
   </div>

  </ng-template>