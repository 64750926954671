import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  apiRoot: string = `${environment.restaurantUrl}/api/restaurantplupricemodels`;
  
  constructor(private http: HttpClient) { }

  saveModel(dataToSave){
    let result = this.http.post<any>(`${this.apiRoot}`,dataToSave);
    return result;
  }

}
